import { Box, Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useMailProvider } from '../../../context/MailContext'
import { requester } from '../../../utils/requester/requester'
import { getTranslatation } from '../../../utils/i18n/getTranslatation'

export const Sender = () => {
    const { t } = getTranslatation()
    const [recipients, setRecipients] = useState<Array<string>>([])
    const [recipient, setRecipient] = useState<string>('')
    const { content } = useMailProvider()

    const sendMail = () => {
        requester(
            '/mail/send/?' + content,
            JSON.stringify({
                recipients: recipients,
            }),
            null,
            'POST'
        )
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {recipients.map(e => ` - ${e} - `)}
            <TextField
                label={t('add', true)}
                sx={{ minWidth: '400px' }}
                value={recipient}
                onChange={evt => setRecipient(evt.target.value)}
                onKeyDown={evt => {
                    if (evt.key === 'Enter') {
                        setRecipients([recipient, ...recipients])
                        setRecipient('')
                    }
                }}
            />
            <Button
                onClick={() => {
                    setRecipients([])
                }}
            >
                {t('clear', true)}
            </Button>
            <Button
                onClick={() => {
                    sendMail()
                }}
            >
                {t('send', true)}
            </Button>
        </Box>
    )
}
