export const GathererStyle = {
    margin: '3rem',
    padding: '1rem',
    borderRadius: '10px',
}
export const GathererContainerStyle = {
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    maxWidth: '60%',
}

export const GathererButtonStyle = {
    margin: '1rem',
}
