import { Box, Button, Paper, TextField } from '@mui/material'
import { MenuHeader } from '../../components/menuHeader/MenuHeader'
import { getTranslatation } from '../../utils/i18n/getTranslatation'
import { Editor } from '../../components/editor/Editor'
import { ArticleFieldContainesStyles, ArticleImgPreview, ArticleStyles, ArticleValidationButtonStyle } from './_styles'
import { useState } from 'react'
import { sendArticle } from '../../api/queries/article'

export const Article = () => {
    const { tu } = getTranslatation()
    const [title, setTitle] = useState<string | null>(null)
    const [description, setDescription] = useState('')
    const [short, setShort] = useState('')
    const [picture, setPicture] = useState<FileList>()
    const [pictureUrl, setPictureUrl] = useState<string[]>([])

    const onValidationHandler = async () => {
        const form = new FormData()
        form.append('title', title ?? '')
        form.append('content', description)
        form.append('short', short)
        if (picture) {
            for (let i = 0; i < picture.length; i++) {
                form.append('picture', picture[i])
            }
        }
        await sendArticle(form)
    }

    const onClosePictureChooser = (evt: React.FormEvent<HTMLInputElement>) => {
        const target = evt.target as HTMLInputElement
        const files = target.files
        if (files) {
            setPicture(files)
            const result: string[] = []
            for (let i = 0; i < files.length; i++) {
                const fr = new FileReader()
                fr.onload = () => {
                    if (fr.result) result.push(fr.result.toString())
                    if (i === files.length - 1) setPictureUrl(result)
                }
                const file = files[i]
                fr.readAsDataURL(file)
            }
        }
    }
    return (
        <Box>
            <MenuHeader />
            <Paper sx={ArticleStyles}>
                <Box sx={ArticleFieldContainesStyles}>
                    <TextField
                        onChange={evt => {
                            setTitle(evt.target.value)
                        }}
                        label={tu('title')}
                    />
                    <TextField
                        onChange={evt => {
                            setShort(evt.target.value)
                        }}
                        label={tu('description')}
                    />
                    <input style={{ display: 'none' }} id="picture" type="file" multiple={true} onChange={onClosePictureChooser} />
                    <Button sx={ArticleValidationButtonStyle} variant="contained" onClick={() => document.getElementById('picture')?.click()}>
                        {tu('picture')}
                    </Button>
                    {pictureUrl.map(url => (
                        <div style={ArticleImgPreview} key={url}>
                            <img style={{ width: '100%' }} src={url} />
                        </div>
                    ))}
                </Box>
                <Editor onChange={setDescription} />
                <Button sx={ArticleValidationButtonStyle} variant="contained" onClick={() => onValidationHandler()}>
                    {tu('valider')}
                </Button>
            </Paper>
        </Box>
    )
}
