import { Box, Button } from '@mui/material'
import { useAuthentication } from '../../context/AuthContext'
import { MenuHeaderStyle } from './_styles'
import { useNavigate } from 'react-router-dom'
import { getTranslatation } from '../../utils/i18n/getTranslatation'

export const MenuHeader = () => {
    const { t } = getTranslatation()
    const navigate = useNavigate()
    const { setSessionToken } = useAuthentication()

    const disconnectHandler = () => {
        setSessionToken(null)
    }

    const goToMenuHandler = () => {
        navigate('/')
    }

    return (
        <Box sx={MenuHeaderStyle}>
            <Button variant="outlined" onClick={disconnectHandler}>
                {t('disconnect', true)}
            </Button>
            <Button variant="contained" onClick={goToMenuHandler}>
                {t('menu', true)}
            </Button>
        </Box>
    )
}
