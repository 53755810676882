import { Box, Button, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ToWords } from 'to-words'
import { Donation } from '../../utils/types'
import { EventFieldContainesStyles, EventValidationButtonStyle } from '../event/_styles'
import { sendDonation } from '../../api/queries/donation'
import { getTranslatation } from '../../utils/i18n/getTranslatation'

export const PersonDonation = () => {
    const { register, handleSubmit, control } = useForm<Donation>()
    const { tu } = getTranslatation()
    const [priceInLetter, setPriceInLetter] = useState('')
    const [price, setPrice] = useState('')

    const onSubmit: SubmitHandler<Donation> = async data => {
        data.priceInLetter = priceInLetter + ' euros'
        data.type = 'person'
        data.donationSubType = 'number'
        await sendDonation(data)
    }

    useEffect(() => {
        try {
            const priceNumber = parseInt(price)
            const toWords = new ToWords({ localeCode: 'fr-FR' }).convert(priceNumber)
            setPriceInLetter(toWords)
        } catch {
            //nothing
        }
    }, [price])
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={EventFieldContainesStyles}>
                <TextField {...register('donationId')} label={tu('donationId')} />
                <TextField {...register('firstname')} label={tu('firstname')} />
                <TextField {...register('lastname')} label={tu('lastname')} />
                <TextField {...register('numberAddress')} label={tu('numberAddress')} />
                <TextField {...register('street')} label={tu('street')} />
                <TextField {...register('postalCode')} label={tu('cityCode')} />
                <TextField {...register('city')} label={tu('city')} />
                <TextField {...register('country')} label={tu('country')} />
                <TextField
                    {...register('price')}
                    label={tu('price')}
                    onChange={e => {
                        setPrice(e.target.value)
                    }}
                />
                <Typography>{`${tu('priceLetter')} : ${priceInLetter}`}</Typography>
                <Typography>{`${tu('donationType')}`}</Typography>
                {
                    <Controller
                        rules={{ required: true }}
                        control={control}
                        name="donationNumberType"
                        render={({ field }) => (
                            <RadioGroup {...field}>
                                <FormControlLabel value="cash" control={<Radio />} label={tu('cash')} />
                                <FormControlLabel value="check" control={<Radio />} label={tu('check')} />
                                <FormControlLabel value="bank" control={<Radio />} label={tu('bank')} />
                            </RadioGroup>
                        )}
                    />
                }
                <TextField {...register('dateOfDonation')} label={tu('dateOfDonation')} />
            </Box>
            <Button sx={EventValidationButtonStyle} variant="contained" type="submit">
                {tu('valider')}
            </Button>
        </form>
    )
}
