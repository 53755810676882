import ArticleIcon from '@mui/icons-material/Article'
import BookIcon from '@mui/icons-material/Book'
import EmailIcon from '@mui/icons-material/Email'
import EventIcon from '@mui/icons-material/Event'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import WebIcon from '@mui/icons-material/Web'
import { Box, Typography } from '@mui/material'
import { MenuHeader } from '../../components/menuHeader/MenuHeader'
import { MenuElementType } from '../../components/menuItem/_props'
import { MenuItem } from '../../components/menuItem/MenuItem'
import logo from '../../static/logo.png'
import { MenuContainerStyles, MenuStyles, MenuTitleContainer, MenuTitleStyles } from './_styles'
import { getTranslatation } from '../../utils/i18n/getTranslatation'
import AddCardIcon from '@mui/icons-material/AddCard'

export const Menu = () => {
    const { t, tu } = getTranslatation()

    const menuItems: Array<MenuElementType> = [
        {
            name: 'site',
            title: t('site'),
            icon: <WebIcon />,
            redirect: 'https://ponse.fr',
        },
        // {
        //     name: 'orsa',
        //     title: t('orsa'),
        //     icon: <TimelineIcon />,
        //     disabled: true,
        // },
        {
            name: 'event',
            title: `${t('add', true)} ${t('event')}`,
            icon: <EventIcon />,
            href: '/event',
        },
        {
            name: 'article',
            title: `${t('add', true)} ${t('article')}`,
            icon: <ArticleIcon />,
            href: '/article',
        },
        {
            name: 'email',
            title: `${t('send', true)} ${t('email ')}`,
            icon: <EmailIcon />,
            href: '/email',
        },
        {
            name: 'donation',
            title: `${tu('donationManagement')}`,
            icon: <VolunteerActivismIcon />,
            href: '/donations',
        },
        {
            name: 'gatherer',
            title: `${tu('gatherer')}`,
            icon: <AddCardIcon />,
            href: '/gatherer',
        },
        // {
        //     name: 'contact',
        //     title: `${t('import', true)} ${t('contacts')}`,
        //     icon: <PersonIcon />,
        //     href: '/contact',
        //     disabled: true,
        // },
        {
            name: 'documentation',
            title: `${t('consult', true)} ${t('documentation')}`,
            icon: <BookIcon />,
            redirect: 'https://docs.google.com/document/d/15jIQsEkSc_jjFK4VFtrREXtwGUBqnvef6SPqTYOZzX4/edit',
        },
    ]

    return (
        <Box>
            <MenuHeader />

            <Box sx={MenuStyles}>
                <Box sx={MenuTitleContainer}>
                    <img style={{ height: '150px' }} src={logo} />
                    <Typography variant="h4" sx={MenuTitleStyles}>
                        {t('menu_title')}
                    </Typography>
                </Box>
                <Box sx={MenuContainerStyles}>
                    {menuItems.map(e => {
                        return <MenuItem key={e.name} element={e} />
                    })}
                </Box>
            </Box>
        </Box>
    )
}
