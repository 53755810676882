import { Autocomplete, Box, Paper, TextField } from '@mui/material'
import { MenuHeader } from '../../components/menuHeader/MenuHeader'
import { getTranslatation } from '../../utils/i18n/getTranslatation'
import { PersonDonation } from './PersonDonation'
import { CompanyDonation } from './CompanyDonation'
import { useMemo, useState } from 'react'
import { MailStyles } from '../mail/_styles'

export const Donations = () => {
    const { t } = getTranslatation()
    const [type, setType] = useState<string | undefined>()

    const mailTypes = [
        {
            name: 'person',
            title: `${t('person', true)}`,
        },
        {
            name: 'company',
            title: `${t('company', true)}`,
        },
    ]

    const donationComponent = useMemo(() => {
        switch (type) {
            case 'person':
                return <PersonDonation />
            case 'company':
                return <CompanyDonation />
            default:
                return null
        }
    }, [type])

    return (
        <Box>
            <MenuHeader />
            <Paper sx={MailStyles}>
                <Autocomplete
                    disablePortal
                    options={mailTypes}
                    sx={{ width: 300 }}
                    getOptionLabel={option => {
                        return option.title
                    }}
                    onChange={(event, value) => setType(value?.name)}
                    renderInput={params => <TextField {...params} label={`${t('type', true)} ${t('of')} ${t('donation')}`} />}
                />
                {donationComponent}
            </Paper>
        </Box>
    )
}
