export const ObservationCategoryValues = ['Oiseaux', 'Amphibiens', 'Papillons', 'Odonates', 'Mammifères', 'Insectes', 'Autres', 'Reptiles']
export type ObservationCategory = (typeof ObservationCategoryValues)[number]
export type Mails = 'nature' | 'simple' | 'free'
export type ObsCategory = {
    name: string
    items: Array<Observation>
}
export type Observation = {
    id: number
    category: ObservationCategory
    name: string
    href: string
}
export type Event = {
    title: string
    short: string
    date: string
    location: string
    description: string
}
export type MailImage = {
    media: File
    name: string
}
export type Donation = {
    donationId: string
    type: 'person' | 'company'
    firstname: string
    lastname: string
    numberAddress: string
    street: string
    postalCode: string
    city: string
    country: string
    price: string
    priceInLetter: string
    donationSubType: 'number' | 'title' | 'leaving' | 'cost' | 'other'
    donationSubTypeText?: string
    donationNumberType?: 'cash' | 'check' | 'bank'
    dateOfDonation: string
}

export type Gatherer = {
    firstName: string
    lastName: string
    address: string
    postalCode: string
    city: string
}
