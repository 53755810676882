import { SxProps, Theme } from '@mui/material'

export function makeSx<T extends { [key: string]: SxProps<Theme> }>(arg: T): T {
    return arg
}

export const downloadBlob = (blob: Blob, fileName: string) => {
    const a = document.createElement('a')
    const url = URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    a.click()
}
