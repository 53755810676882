import { Box, Button, Paper, TextField } from '@mui/material'
import { useState } from 'react'
import { sendEvent } from '../../api/queries/event'
import { Editor } from '../../components/editor/Editor'
import { getTranslatation } from '../../utils/i18n/getTranslatation'
import { EventFieldContainesStyles, EventStyles, EventValidationButtonStyle } from './_styles'
import { MenuHeader } from '../../components/menuHeader/MenuHeader'
import moment from 'moment'

export const Event = () => {
    const { tu } = getTranslatation()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [date, setDate] = useState('')
    const [hour, setHour] = useState('')
    const [location, setLocation] = useState('')
    const [short, setShort] = useState('')
    const [picture, setPicture] = useState<File | null>()
    const [pictureUrl, setPictureUrl] = useState<string>('')

    const onValidationHandler = () => {
        const form = new FormData()
        form.append('title', title)
        form.append('content', description)
        form.append('short', short)
        form.append('date', moment(date, 'DD/MM/YYYY').utc(true).toString())
        form.append('hour', hour)
        form.append('location', location)
        if (picture) form.append('picture', picture)
        sendEvent(form)
    }

    const onClosePictureChooser = (evt: React.FormEvent<HTMLInputElement>) => {
        const target = evt.target as HTMLInputElement
        const files = target.files
        if (files) {
            setPicture(files[0])
            const fr = new FileReader()
            fr.onload = () => {
                if (fr.result) setPictureUrl(fr.result.toString())
            }
            fr.readAsDataURL(files[0])
        }
    }

    return (
        <Box>
            <MenuHeader />
            <Paper sx={EventStyles}>
                <Box sx={EventFieldContainesStyles}>
                    <TextField
                        onChange={evt => {
                            setTitle(evt.target.value)
                        }}
                        label={tu('title')}
                    />
                    <TextField
                        onChange={evt => {
                            setDate(evt.target.value)
                        }}
                        label={tu('date')}
                    />
                    <TextField
                        onChange={evt => {
                            setHour(evt.target.value)
                        }}
                        label={tu('hour')}
                    />
                    <TextField
                        onChange={evt => {
                            setLocation(evt.target.value)
                        }}
                        label={tu('location')}
                    />
                    <TextField
                        onChange={evt => {
                            setShort(evt.target.value)
                        }}
                        label={tu('description')}
                    />
                    <input style={{ display: 'none' }} id="picture" type="file" onChange={onClosePictureChooser} />
                    <Button sx={EventValidationButtonStyle} variant="contained" onClick={() => document.getElementById('picture')?.click()}>
                        {tu('picture')}
                    </Button>
                    <img src={pictureUrl} />
                </Box>
                <Editor onChange={setDescription} />
                <Button sx={EventValidationButtonStyle} variant="contained" onClick={() => onValidationHandler()}>
                    {tu('valider')}
                </Button>
            </Paper>
        </Box>
    )
}
