import { Box, Button, Paper, TextField } from '@mui/material'
import { MenuHeader } from '../../components/menuHeader/MenuHeader'
import { GathererButtonStyle, GathererContainerStyle, GathererStyle } from './_styles'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Gatherer } from '../../utils/types'
import { getTranslatation } from '../../utils/i18n/getTranslatation'
import { sendGatherer } from '../../api/queries/gatherer'

export const GathererForm = () => {
    const { register, handleSubmit, control } = useForm<Gatherer>()
    const { tu } = getTranslatation()

    const onSubmit: SubmitHandler<Gatherer> = async data => {
        await sendGatherer(data)
    }

    return (
        <Box>
            <MenuHeader />
            <Paper sx={GathererStyle}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={GathererContainerStyle}>
                        <TextField {...register('firstName')} label={tu('firstname')} />
                        <TextField {...register('lastName')} label={tu('lastname')} />
                        <TextField {...register('address')} label={tu('address')} />
                        <TextField {...register('postalCode')} label={tu('cityCode')} />
                        <TextField {...register('city')} label={tu('city')} />
                    </Box>
                    <Button sx={GathererButtonStyle} variant="contained" type="submit">
                        {tu('valider')}
                    </Button>
                </form>
            </Paper>
        </Box>
    )
}
